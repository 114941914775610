body, html {
    margin:0 !important;
    padding:0 !important;
    font-family: 'Inter' !important;
}

h2 {
    font-family: 'Inter';
}

.containerr {
    display: -ms-grid ;
    display: grid ;
    overflow-x: hidden;
    position: relative;
    height:100%;
    /*grid-template-columns: [first] auto*/
    -ms-grid-rows: [row1-start] 50vh [row2] 18vw [row3] 50vh [row4] 50vh [row5] 10vh [row6] 50vh [row7] 40vh [row8] 80vh [row9] 5vw [row10] 15vw 
    [row11] calc(180vh - 40vw) [row12] 195vh [row13-end] 25vh;
    grid-template-rows: [row1-start] 50vh [row2] 18vw [row3] 50vh [row4] 50vh [row5] 10vh [row6] 50vh [row7] 40vh [row8] 80vh [row9] 5vw [row10] 15vw 
    [row11] calc(180vh - 40vw) [row12] 195vh [row13-end] 25vh;
}

@media screen and (max-width: 610px)  {
    .containerr {
        display: grid ;
        overflow: hidden;
        /*grid-template-columns: [first] auto*/
        -ms-grid-rows: [row1-start] 50vh [row2] 18vw [row3] 50vh [row4] 50vh [row5] 10vh [row6] 50vh [row7] 40vh [row8] 80vh [row9] 5vw [row10] 15vw 
        [row11] calc(180vh - 40vw) [row12] 280vh [row13-end] 25vh;
        grid-template-rows: [row1-start] 50vh [row2] 18vw [row3] 50vh [row4] 50vh [row5] 10vh [row6] 50vh [row7] 40vh [row8] 80vh [row9] 5vw [row10] 15vw 
        [row11] calc(180vh - 40vw) [row12] 280vh [row13-end] 25vh;
    }
}

/* _::-webkit-full-page-media, _:future, :root .containerr {
    display: grid ;
    overflow: hidden ;
    /*grid-template-columns: [first] auto
    grid-template-rows: [row1-start] 50vh [row2] 18vw [row3] 60vh [row4] 50vh [row5] 10vh [row6] 50vh [row7] 40vh [row8] 80vh [row9] 5vw [row10] 15vw 
    [row11] calc(180vh - 40vw) [row12] 130vh [row13-end] 25vh;
} */

#mainlogo {
    height: 47vh;
    max-width: 75vw;
    min-width: 25vw;
    position: absolute;
    left: 5vw;
    -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
            transform: rotate(15deg);
    margin-top: 5vh;
}
.stripe1 {
    position:absolute;
    background-color: rgb(177, 69, 227, .43);
    -webkit-transform: rotate(-12deg) skew(-12deg);
        -ms-transform: rotate(-12deg) skew(-12deg);
            transform: rotate(-12deg) skew(-12deg);
    height: 50vh;
    width: 120%;
    margin-left: -5vw;
    float:left;

    -ms-grid-row: 1;

        grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;  
}

/* _::-webkit-full-page-media, _:future, :root .stripe1 {
    position:relative;
    background-color: rgb(177, 69, 227, .43);
    transform: rotate(-12deg) skew(-12deg);
    height: 50vh;
    width: 120%;
    margin-left: -5vw;

    grid-row-start: 1;
    grid-row-end: 2;

    display:flex;
    justify-content: center;
    align-items: center;  
} */

.stripe2 {
    position: relative;
    background-color: rgb(138, 242, 144, .48);
    -webkit-transform: rotate(12deg) skew(12deg) ;
        -ms-transform: rotate(12deg) skew(12deg) ;
            transform: rotate(12deg) skew(12deg) ;

    height: 50vh;
    width: 120%;
    margin-left: -5vw;

    -ms-grid-row: 3;

        grid-row-start: 3;
}

@media screen and (max-width: 610px)  {

    .stripe2 {
        position: absolute;
        background-color: rgb(138, 242, 144, .48);
        -webkit-transform: rotate(12deg) skew(12deg) ;
            -ms-transform: rotate(12deg) skew(12deg) ;
                transform: rotate(12deg) skew(12deg) ;
    
        height: 50vh;
        width: 120%;
        margin-left: -5vw;
    
        -ms-grid-row: 3;
    
            grid-row-start: 3;
    }
}
/* 
_::-webkit-full-page-media, _:future, :root .stripe2 {
    position: relative;
    background-color: rgb(138, 242, 144, .48);
    transform: rotate(12deg) skew(12deg) translateY(20%);

    height: 50vh;
    width: 100%;
    margin-left: -5vw;

    grid-row-start: 3;
} */

.register_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 3vw;
    margin-left: 3vw;
}



.reg_button {
    font-family: 'Inter' !important;
    font-size: calc(1.75vw + 1.7vh);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #79E77FAD;
    color: #55FF5E;
    float: left;
    padding: 1vh 1vw;
    border-radius: 50px;
}

.reg_button:hover {
    padding: calc(0.9vh) calc(0.9vw);
    border: calc(0.1vh + 0.1vw) solid #55FF5E;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    background: #fff;
    color: #57a55b;
}
@media screen and (max-width: 1100px)  {
    .register_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 1vw;
        margin-left: 0vw;
    }
    .reg_button {
        font-family: 'Inter' !important;
        font-size: calc(1.75vw + 1.7vh);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background-color: #79E77FAD;
        color: #55FF5E;
        float: left;
        padding: 1.3vh 1vw;
        border-radius: 50px;
    }
    
    .reg_button:hover {
        padding: calc(1.2vh) calc(0.9vw);
        border: calc(0.1vh + 0.1vw) solid #55FF5E;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        background: #fff;
        color: #57a55b;
    }
}

@media screen and (max-width: 610px)  {
    .register_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 0vw;
        margin-left: 0vw;
    }
    .reg_button {
        font-family: 'Inter' !important;
        font-size: calc(1.75vw + 1.7vh);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background-color: #79E77FAD;
        color: #55FF5E;
        float: left;
        padding: 1.3vh 1vw;
        border-radius: 50px;
    }
    
    .reg_button:hover {
        padding: calc(1.2vh) calc(0.9vw);
        border: calc(0.1vh + 0.1vw) solid #55FF5E;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        background: #fff;
        color: #57a55b;
    }
}




.stripe2_line1 {
    width: 35vw;
    margin-left: 15vw;
    border-top: #F71FC7 dashed 2vh;
    position:absolute;
    -webkit-transform: skew(-12deg);
        -ms-transform: skew(-12deg);
            transform: skew(-12deg);
}
.stripe2_line2 {
    width: 50vw;
    margin-left: 50vw;
    border-top: #55FF5E dashed 2vh;
    position: absolute;
}

.stripe2_content {
    -webkit-transform: skew(-12deg);
        -ms-transform: skew(-12deg);
            transform: skew(-12deg);
    text-align: right;  
    color: #55FF5E;
    font-size: calc(3vh + 1vw);
    padding-right: 20%;
    padding-left: 15%;
}
.stripe2_content h2 {
    text-align: right;
    margin-bottom: 5px;
}
.info1 {
    height: 40vh;
    position:absolute;
    border: #55FF5E solid calc(0.5vw + 0.7vh);
    border-radius: 2vw;

    width: calc(50vw);
    max-width: 80vw;
    min-width: 300px;

    top: 0;
    margin-top: 50vh;
    margin-left: 15vw;

}

#info1 {
    color: white;
    padding: 10px;
    font-size: calc(1.96vh + 0.5vw);
    text-shadow: -4px 3px 10px rgb(85, 255, 94, 0.5);
}

.shape1 {
    border: #79E77F solid 1.5vw;
    border-radius: 50vw;
    height: 10vw;
    width: 60vw;
    margin-top: 50vh;

    position: absolute;
    right: 0;
}

@media screen and (max-width: 610px)  {
    .shape1 {
        border: #79E77F solid 1.5vw;
        border-radius: 50vw;
        height: 10vw;
        width: 70vw;
        position: absolute;
        right: 0;
    }
}



#presented {
    max-width: 70%;
    max-height: 50%;
    position: absolute;
    top: -7vw;
}
.event {
    display: absolute;
    -ms-grid-row:5;
        grid-row-start:5;
    -ms-grid-row-span:2;
    grid-row-end:7;
    z-index: 3;
}
.event1 {
    background-color: rgb(99, 223, 247, 0.17);

    height: 10vh;
    width: 70vw;
    margin-bottom: 10px;
    
    margin-right: 15vw;
    margin-left: 15vw;
    border-radius: 4vw;
    /*grid-row-start: 5;
    grid-row-end: 6;*/

    z-index: 2;
}

.event2 {
    background-color: rgb(99, 223, 247, 0.17);

    border: #63DFF7 solid 1vw;
    border-radius: 4vw;

    /*grid-row-start: 6;
    grid-row-end: 7;*/

    margin: auto;

    height: 50vh;

    width: 70vw;

    z-index: 3;

    padding: 10px;

    color:white;

}

#id {
    color: white;
    font-size: 4vw;
}
.nav-link {
    border-color: red;
}

.stripe3 {
    background-color: rgb(177, 69, 227, .44);
    -webkit-transform: rotate(12deg) skew(12deg);
        -ms-transform: rotate(12deg) skew(12deg);
            transform: rotate(12deg) skew(12deg);
    height: 50vh;
    width: 120%;
    margin-left: -5vw;

    -ms-grid-row: 7;

        grid-row-start: 7;
    -ms-grid-row-span: 1;
    grid-row-end: 8;

    z-index: 1;
}

.stripe3_content {
    -webkit-transform: skew(-12deg);
        -ms-transform: skew(-12deg);
            transform: skew(-12deg);
    color: #F71FC7;
    font-size:calc(30px + 5vw);

    position: absolute;
    bottom: 0;
    right: 0;
    
    margin-right: 30vw;
}

.sponsers {
    /*background-color: rgba(0, 229, 255, 0.2);*/
    color: #F71FC7;
    -ms-grid-row: 8;
        grid-row-start: 8;
    -ms-grid-row-span: 1;
    grid-row-end: 9;
    width: 50vw;
    min-width: 350px;
    max-width: 80vw;
    height: 100%;

    margin-left: 5vw;
    z-index: 1;
}

.sponsers h3 {
    margin-left: 10px;
    font-size: 5vh;
    visibility: hidden;
}

@media (max-width: 900px) {
    .sponsers h3 {
        visibility: hidden;
    }
}

.sponser_info {
    /*background-color: rgba(255, 123, 0, 0.585);*/
    height: 30vh;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [img] 25vw [nothing] 5vw [info];
    grid-template-columns: [img] 25vw [nothing] 5vw [info]
}

.sponser_img {
    -ms-grid-column: 1;
        grid-column-start: 1;
    width: 100%;
    margin:40px 0px;
    display: block;
}

.sponser_text {
    -ms-grid-column: 3;
        grid-column-start: 3;
    color: white;
    font-size: 1vw;
    width: 30vw;
}
@media screen and (max-width: 1230px) {
    .sponser_text {
        font-size: 14px;
        width:400px;
    }
}
@media screen and (max-width: 950px) {
    .sponser_text {
        font-size: 14px;
        width:400px;
    }
}
@media screen and (max-width: 610px) {
    .sponser_text {
        font-size: 9px;
        width:200px;
    }
}

.stripe4 {
    background-color: rgb(99, 223, 247, .90);
    -webkit-transform: rotate(-28deg) skew(-28deg);
        -ms-transform: rotate(-28deg) skew(-28deg);
            transform: rotate(-28deg) skew(-28deg);
    height: 50vh;
    width: 120%;
    margin-left: -10vw;

    -ms-grid-row: 9;

        grid-row-start: 9;
    z-index: 0;
}

.stripe4_content {
    /*transform: skew(28deg) rotate(28deg);*/
    color: #002830;
    font-size: 5vh;
    margin-bottom: 10px;
}

.stripe4_details {
    background-color: #084855;
    color: #71E8FF;

    width: 50vw;

    /*transform: skew(28deg) rotate(28deg) skew(-10deg);*/
    -webkit-transform: skew(-10deg);
        -ms-transform: skew(-10deg);
            transform: skew(-10deg);
    
    border-radius: 40px;

    padding: 20px;

    font-size:calc(10px + 2vw);
    font-weight: bold;
}
.stripe4_container {
    margin-left: 20vw;
    -ms-grid-row: 11;
        grid-row-start: 11;
    z-index: 2;
}
.faq {
    -ms-grid-row: 10;
        grid-row-start: 10;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [faq_text] 75vw [nothing] 5vw [faq_stripe];
    grid-template-columns: [faq_text] 75vw [nothing] 5vw [faq_stripe]
}

.faq_text {
    -ms-grid-column: [faq_text];
        grid-column-start: [faq_text];
    grid-column-end: [nothing];
    padding-left: 5vw;
    padding-top: calc(20vw + 60vh);
}

.faq_block {
    color: white;
    border-left: #F71FC7 solid 1.5vw;
    border-radius: 15px 0px 0px 15px;
    margin-bottom: 20px;
    padding-left: 5px;
    font-family: 'Inter' !important;
    
    font-size: calc(0.75vw + 1.7vh);
}

.faq_block .q {
    font-size: calc(1vw + 1.7vh);
    font-weight: bold;
    font-style: italic;
    padding-bottom: 3px;
}

.faq_stripe {
    border: #F71FC7 solid 1.5vw;
    border-radius: 50vw;
    height: calc(340vh - 30vw);
    width: 10vw;
    -ms-grid-column: 3;
        grid-column-start: 3;
}


.faq_pill {
    background-color: rgba(177, 69, 227, 0.39);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60vh;
    border-radius: 40vw;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-size: 11.5vw;
    line-height:20vh;
    text-align:center;
    padding-top: 15vh;
    padding-bottom: 15vh;
    width:10vw;
    color: #F71FC7;
}

.footer {
    -ms-grid-row: 13;
        grid-row-start: 13;
    background-color: rgb(121, 231, 127, .58);
}
.footer_content {
    margin-right: 10vw;
    margin-left: 2vw;
    height: 100%;
}
#mainlogo_footer {
    height: 100%;
    max-width: 50%;
}
.socials {
    height: 50%;
    float: right !important;
    margin-left: 20px;
    margin-top: 6vh;
    max-width: 10%;
}